import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const Process = makeShortcode("Process");
const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-life-is-a-work-of-art",
      "style": {
        "position": "relative"
      }
    }}>{`😂 Life is a work of art`}<a parentName="h1" {...{
        "href": "#-life-is-a-work-of-art",
        "aria-label": " life is a work of art permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <Video src="https://www.youtube-nocookie.com/embed/Aalyplbv5Mo?start=85" mdxType="Video" />
    <p>{`We've covered the beginnings of Ethereum, the effects it's likely to have on our capitalist
society in the long-term, and the deeper history of data integrity into which this all fits,
but the big question still remains: what does it actually `}<em parentName="p">{`mean`}</em>{`? For that, we must turn away
from the men and invite the sparkling character of May Li Khoe onto the stage.`}</p>
    <h2 {...{
      "id": "how-does-this-fit-into-kernel",
      "style": {
        "position": "relative"
      }
    }}>{`How does this fit into Kernel?`}<a parentName="h2" {...{
        "href": "#how-does-this-fit-into-kernel",
        "aria-label": "how does this fit into kernel permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`This wonderful presentation has been selected to deepen your appreciation of `}</p>
    <Process mdxType="Process">
      <p>{`Where `}<strong parentName="p">{`meaning`}</strong>{` stems from`}</p>
      <p>{`How we can design our own perceptions of the world to generate more meaning `}</p>
      <p>{`How we can create `}<strong parentName="p">{`value`}</strong>{` both for ourselves and the communities of which we are a part`}</p>
    </Process>
    <h2 {...{
      "id": "brief",
      "style": {
        "position": "relative"
      }
    }}>{`Brief`}<a parentName="h2" {...{
        "href": "#brief",
        "aria-label": "brief permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`May Li begins by speaking about her mixed ancestry and the effect this has had on her approach
to, and understanding of, value and meaning. As one of the designers who worked on the first
iPhone, she has a unique perspective on the ability we have to design both. She also speaks
about the US-sanctioned massacres in Indonesia in 1965 - one of the places she originates
from - and how these, too, have effected the way she sees the structure of society and what she
calls "dominant narratives."`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Instead of thinking of life as a series of checks which I need to tick off - something which
can be displayed on a graph that climbs ever up and to the right - I like to think of my life
as a canvas which I can paint with whatever weird artwork I feel like `}{`[...]`}{` Here is my mandatory
Venn diagram: the status quo needs to change, and life is short. When we put these two together,
we can see that we need to subvert the status quo and have as much fun as possible along the way!"`}</p>
    </blockquote>
    <p>{`It's colourful and light and funny, but also a deeply profound point. To illustrate further: the
point of meditation is not to get "good" at sitting around, thinking about nothing: it is to
turn your life into a meditative one. Every act itself a meditation. May Li talks about it in
terms of turning the living of life into an artwork. The question is, what is the primary means we have to make art of our lives? This course will contend that it is `}<em parentName="p">{`designing dialogue`}</em>{`. Therefore, we turn back and listen further:`}</p>
    <blockquote>
      <p parentName="blockquote">{`"The first example is BBQ Becky, a woman who called the police on black people having a
barbecue `}{`[... The response was to host a really massive barbecue and]`}{` the community has come
together, everyone is welcome, thousands of people turned out. There was great food, and
dominoes. And now it's a tradition! `}{`[...]`}{` The second example is called 'futbolistas 4 life'
`}{`[...]`}{` Something that was important for a lot of the students was feeling like they had this
space to play; and freedom and ownership over their play and their bodies in a place that felt
good `}{`[...]`}{` 'Having a space like a soccer field where we can play and love one another:
`}<em parentName="p">{`that is an act of resistance`}</em>{`'."`}</p>
    </blockquote>
    <p><strong parentName="p">{`In an empire of lies, telling the truth is a revolutionary act. In a fearful society;
love and trust are the primary tools of resistance.`}</strong>{` `}</p>
    <p>{`Play allows us to create and share ownership of spaces in ways which competition cannot. This is why we have unicorns and dancing developers
and silly memes: it's not something incidental. It is a fundamental part of what borderless,
global history-writing based on consensus is about. The revolution is not being televised
because it's not about hate or anger or violence or anything else that grabs the headlines of a
media operating with skewed incentives. It's heart to heart, here in the prison yards where we're
using matching funds to build playgrounds where we can love again.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: What does true play allow us to do?`}</p>
        <p>{`Answer this question in any way we like!`}</p>
        <p>{`Also, subvert the status quo. Have as much fun as possible along the way. Turn life into a canvas, rather than a graph with checkpoints. Welcome everyone. Eat great food. Enjoy dominoes again.`}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "small-things",
      "style": {
        "position": "relative"
      }
    }}>{`Small things`}<a parentName="h3" {...{
        "href": "#small-things",
        "aria-label": "small things permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <blockquote>
      <p parentName="blockquote">{`"When you do these little, joyful subversions remember that they can be really small. They don't
have to be these grandiose events with hundreds or thousands of people. When face-tracking came
out, I managed to sneak little pink hearts into your Mac. At that time, there wasn't a lot of
pink happening at all `}{`[`}{`laughs`}{`]`}{` It makes me happy to think I got Steve Jobs to approve this while
pink hearts were coming out his head. Another example is the Technics 1200 turntables as your
avatar - iconic in hip hop - and some other Easter eggs which are still in MacOS."`}</p>
    </blockquote>
    <p>{`May Li goes on to talk about Scribble Together and her approach to presentation. One of the
examples she uses is the ability to "stamp multiple unicorns". Everything is connected.`}</p>
    <h3 {...{
      "id": "experiment-with-format",
      "style": {
        "position": "relative"
      }
    }}>{`Experiment with format`}<a parentName="h3" {...{
        "href": "#experiment-with-format",
        "aria-label": "experiment with format permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <blockquote>
      <p parentName="blockquote">{`"Another thing you can do is experiment with format `}{`[...]`}{` For instance, using this traditional
talk format to instead make everyone dance `}{`[...]`}{` Another one is subverting technology. This one
is pretty profound. We talk a lot about the epidemic of loneliness `}{`[...]`}{` somehow, we have
accepted the fact that computation has to be locked behind these little rectangles `}{`[...]`}{` How
many years are we into the journey of computers and yet we still accept that we can get locked
into things like this!? What would happen if we turned computers inside out? There's an
organization called `}<a parentName="p" {...{
          "href": "https://dynamicland.org/"
        }}>{`DynamicLand`}</a>{` which does exactly this. It's like
cooking or scrapbooking; except it's all software and we're building things together."`}</p>
    </blockquote>
    <p>{`It's worth knowing a little bit about `}<a parentName="p" {...{
        "href": "https://tinyurl.com/worriedream"
      }}>{`Bret Victor`}</a>{` and the work him
and people like him are doing on computation as a concept and practice, rather than just writing
more software in a linear, boxed-in kind of way. Stay with this until May Li makes it rain
purple across everyone's prototypes with a Prince override. We'll return to Bret later in the
course.`}</p>
    <h3 {...{
      "id": "create-a-space",
      "style": {
        "position": "relative"
      }
    }}>{`Create a space`}<a parentName="h3" {...{
        "href": "#create-a-space",
        "aria-label": "create a space permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <blockquote>
      <p parentName="blockquote">{`"Creating a space for change does not necessarily mean you're doing it yourself; you're just
making it possible for others."`}</p>
    </blockquote>
    <p>{`May Li discusses La Pelanga, a DJ collective she helped form that holds parties in the Bay Area
and does interesting things with their celebratory space. The first one, for instance, raised
funds for Haiti. Again: light, fun, colourful, and profound. A friend once said,
"I'm not all that interested in Jesus, you know. I'm much more interested in what on earth was
going on with that water he walked on!" By which he meant that real miracles occur when you
provide the environment for others to achieve seemingly miraculous things.`}</p>
    <h3 {...{
      "id": "minding-symbolism",
      "style": {
        "position": "relative"
      }
    }}>{`Minding Symbolism`}<a parentName="h3" {...{
        "href": "#minding-symbolism",
        "aria-label": "minding symbolism permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <blockquote>
      <p parentName="blockquote">{`"When you think about dominant narrative and the status quo, symbolism is a big part of that,
because a lot of how we relate to each other is through symbols and stories."`}</p>
    </blockquote>
    <p>{`This time, May Li and friends took a traditional gallery space, built a replica of the proposed
wall between the USA and Mexico out of pinatas and then hosted a big party to do what is
traditionally done to pinatas: smash them up! The footage is a treat to behold.`}</p>
    <p>{`Play with format and mindful symbolism extends a great deal further. For another wonderful example of this way of being and expressing, see Scott Benesiinaabandan's short story `}<em parentName="p">{`Gwiizens, the Old Lady and the Octopus Bag Device`}</em>{` from page 45, and Dr Noelani Arista's essay, `}<em parentName="p">{`Indigenzing AI`}</em>{` from page 102 in the `}<a parentName="p" {...{
        "href": "https://spectrum.library.concordia.ca/id/eprint/986506/7/Indigenous_Protocol_and_AI_2020.pdf"
      }}>{`Indigenous Protocol Paper`}</a>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`The `}<em parentName="p">{`adizkookaan`}</em>{` and the `}<em parentName="p">{`memoryspacetime`}</em>{` where they reside are strange things...`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`By being playfully literate on one level, and also supplying a layer of `}<em parentName="p">{`kaona`}</em>{` or hidden meaning, we offer people an invitation to open themselves to deepen engagement.`}</p>
    </blockquote>
    <h3 {...{
      "id": "building-on-history",
      "style": {
        "position": "relative"
      }
    }}>{`Building on history`}<a parentName="h3" {...{
        "href": "#building-on-history",
        "aria-label": "building on history permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <blockquote>
      <p parentName="blockquote">{`"I think change is rarely straightforward and, unlike the covers of magazines tend to show,
it doesn't happen with one person on their own. We have a lot of room to build on history, to
build on all the tremendous work of people before us who have striven for a better world. One
group of people who did something that was pretty important were the Black Panthers. One thing
people don't realise about them is that they instituted a free breakfast program for children.
We talk a lot about education and all the different statistics and projects there, but if people
come to class hungry they cannot learn `}{`[`}{`...`}{`]`}{` This free breakfast program actually ended up being the blueprint for the Federal free breakfast program which happens today."`}</p>
    </blockquote>
    <p>{`May Li uses this example to discuss the People's Kitchen Collective.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"These are recipes of resilience. The strength of our communities is, at its heart, based on
the meals we share together."`}</p>
    </blockquote>
    <p>{`Please ping Vivek to discuss the importance of food. He has many hot takes on this topic.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: What are the five ways in which we can joyfully subverting the status quo?`}</p>
        <ol>
          <li parentName="ol">{`Do small things.`}</li>
          <li parentName="ol">{`Experiment with format.`}</li>
          <li parentName="ol">{`Create space (not products!).`}</li>
          <li parentName="ol">{`Mind symbolism.`}</li>
          <li parentName="ol">{`Build on history.`}</li>
        </ol>
      </Card>
    </Flash>
    <h3 {...{
      "id": "shifting-the-nature-of-work",
      "style": {
        "position": "relative"
      }
    }}>{`Shifting the nature of work`}<a parentName="h3" {...{
        "href": "#shifting-the-nature-of-work",
        "aria-label": "shifting the nature of work permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <blockquote>
      <p parentName="blockquote">{`"I spent many years in conference rooms and, at some point in time after being at Apple for a
little while, I felt like I wanted to do something a little different and I wound up joining
Khan Academy. There were a couple of reasons I was attracted to it: one of them was that they
are a not-for-profit which is building software, and that's pretty rare. I thought, 'hmmm, what
would happen if our `}<strong parentName="p">{`incentive`}</strong>{` structures were a little different?' I wanted to democratize
learning too, so the `}<strong parentName="p">{`mission`}</strong>{` was really profound for me. And the last thing was about the
`}<strong parentName="p">{`culture`}</strong>{`, because I felt that the organization was really open to some experimentation with
cultural shifts.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"We wanted to shift educational software from assessing students and just asking 'Do you get
it yet?' to letting them explore magical worlds themselves where, for instance, understanding
the mathematics would give you powers `}{`[...]`}{` And all kinds of interesting stuff fell out of it:
visual and interactive properties of prime numbers and so on.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"People tend to have a very vivid imagination when they work in design: you actually, literally, have the tools and you're constantly drawing possible futures. When you are iterating, you are literally drawing multiple possible futures. So, if you take a second and think about something that `}<em parentName="p">{`you`}</em>{` want to change, and you look around you - there are so many potential co-conspirators here! - this is really an invitation to `}<strong parentName="p">{`you`}</strong>{` to make some friends and start some shit and joyfully subvert the status quo!"`}</p>
    </blockquote>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: What are the three keys to shifting the nature of work?`}</p>
        <ol>
          <li parentName="ol">{`Incentives.`}</li>
          <li parentName="ol">{`Mission.`}</li>
          <li parentName="ol">{`Culture.`}</li>
        </ol>
      </Card>
    </Flash>
    <p>{`If you look around the more interesting corners of our world wide web, you'll find many convergent ideas, like this one from `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Staying_with_the_Trouble"
      }}>{`Donna Haraway`}</a>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Trouble is an interesting word. It derives from a thirteenth-century French verb meaning “to stir up,” “to make cloudy,” “to disturb.” We—all of us on Terra—live in disturbing times, mixed-up times, troubling and turbid times. `}<strong parentName="p">{`The task is to become capable, with each other in all of our bumptious kinds, of response`}</strong>{`. Mixed-up times are overflowing with both pain and joy—with vastly unjust patterns of pain and joy, with unnecessary killing of ongoingness but also with necessary resurgence.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`The task is to make kin in lines of inventive connection as a practice of learning to live and die well with each other in a `}<strong parentName="p">{`thick present`}</strong>{`.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Our task is to make trouble, to stir up potent responses to devastating events, as well as to settle troubled waters and rebuild quiet places. In urgent times, many of us are tempted to address trouble in terms of making an imagined future safe, of stopping something from happening that looms in the future, of clearing away the present and the past in order to make futures for coming generations.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Staying with the trouble does not require such a relationship to times called the future. In fact, staying with the trouble requires learning to be truly present, not as a vanishing pivot between awful or edenic pasts and apocalyptic or salvific futures, but as mortal critters entwined in myriad unfinished configurations of places, times, matters, meanings.`}</p>
    </blockquote>
    <p>{`Lest you think it is all just a serious affair - this staying with the trouble - remember the trouble you caused as a young child and `}<a parentName="p" {...{
        "href": "https://jackkornfield.com/natural-joy/"
      }}>{`the natural joy it can bring`}</a>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“Know that joy is rarer, more difficult, more beautiful than sorrow. To make this discovery is to embrace joy as a moral obligation.” - André Gide`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      